import { navigate } from 'gatsby';
import { FC, useCallback, useEffect, useId, useMemo, useState } from 'react';
import menu from "../../../../editor/src/menus/map.menu";
import { useMapData } from "../../queries";
import { MapDatum, MapEdge } from "../../types";
import { MenuLayout } from '../menu/MenuLayout';
import { TCommand } from '../menu/MenuTypes';
import PleaseWaitComponent from "../misc/pleaseWaitComponent";
import { MapNewComponent } from './MapNew';
import { MapToolbar } from './MapToolbar';
import { generateTestMap } from './test';
import { zoomIn, zoomOut } from './zoom';


const MapEditorComponent: FC<{ path: string }> = ({ path }) => {
  const { data, isLoading, isError } = useMapData(path);
  const [zoom, setZoom] = useState(100);
  const [nodeSelected, _setNodeSelected] = useState(false);

  // Use test data if there is no data and running on localhost
  const getNodesAndEdges = () => {
    if ((data && data.nodes.length !== 1) || document?.location.hostname !== "localhost") {
      return data ? { nodes: data.nodes, edges: data.edges } : null;
    } else {
      return generateTestMap();
    }
  };
  const nodesAndEdges = useMemo(getNodesAndEdges, [data]);

  const execCommand = (command: TCommand) => {
    if (command === "Quit") {
      navigate("/o/" + path);
    } else {
      console.log("execCommand", command);
    }
  };

  if (isLoading) return <PleaseWaitComponent></PleaseWaitComponent>;
  if (isError || /* !data */ !nodesAndEdges) return <div>Nie udało się pobrać danych mapy.</div>;
  const { nodes, edges } = nodesAndEdges!;

  return <MenuLayout
    execCommand={execCommand}
    menu={menu}
    toolbar={<MapToolbar nodeSelected={nodeSelected} zoom={zoom} setZoom={setZoom} execCommand={execCommand} />}
  >
    <MapArea nodes={nodes} edges={edges} zoom={zoom} setZoom={setZoom} />
  </MenuLayout>;
};

const MapArea: FC<{
  nodes: MapDatum[], edges: MapEdge[], zoom: number, setZoom: (zoom: number) => void
}> = ({ nodes, edges, zoom, setZoom }) => {
  const onWheel = useCallback((e: WheelEvent) => {
    const delta = e.deltaY;
    if (delta === 0) return;
    const newZoom = delta < 0 ? zoomIn(zoom) : zoomOut(zoom);
    setZoom(newZoom);
  }, [zoom]);

  const blurID = useId();
  return <svg onWheel={onWheel as any} viewBox='-1 -1 2 2'>
    <filter id={blurID}><feGaussianBlur in="SourceGraphic" stdDeviation="5" /></filter>
    <g transform={`scale(${zoom / 100})`}>
      <ellipse cx={0} cy={0} rx={1} ry={1} fill="#FFEEDD" filter={`url(#${blurID})`} />
      <MapNewComponent nodes={nodes} edges={edges} animate={true} />
    </g>
  </svg>;
};

export default MapEditorComponent;
