import { FC, useId } from "react";
import styled from "styled-components";

export const LexiaBoxComponent: FC<{
  title: string;
  text?: string;
  x?: number;
  y?: number;
  scale?: number;
  href?: string;
}> = ({ title, text, x, y, scale, href }) => {
  const id = useId();
  text = text || `
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
    ut laoreet dolore Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
    euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
    quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea, ut wisi enim ad.
  `;
  scale = scale || 1;
  return <StyledContainer transform={`matrix(${scale},0,0,${scale},${x || 0},${y || 0})`}>
    <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="75%"  stopColor="#ffffff" stopOpacity="0"/>
      <stop offset="90%"  stopColor="#ffffff" stopOpacity="0.7"/>
      <stop offset="100%" stopColor="#ffffff" stopOpacity="1"/>
    </linearGradient>
    <a href={href}>
      <rect x="0.018" y="0.15067" fill="#FFFFFF" width="0.96389" height="0.83122"/>
      <rect x="0.018" y="0.018" fill="#E5E5E5" width="0.96389" height="0.13267"
        stroke="#000000" strokeWidth="0.01667" className="border" />
      <text x="0.05764" y="0.11547" fontWeight="bold" fontSize="0.07944">{ title }</text>
      <g transform="scale(0.01111111)">
        <foreignObject x="4.44" y="16" width="81" height="71.7" fontSize="5.25px">
          <div>{ text }</div>
        </foreignObject>
      </g>
      <rect x="0.018" y="0.018" fill={`url(#${id})`} width="0.96389" height="0.96389"/>
      <path d="M87.62,2.38v85.25H2.38V2.38H87.62 M89.12,0.88H0.88v88.25h88.25V0.88L89.12,0.88z"
        transform="scale(0.01111111)" className="border" />
    </a>
  </StyledContainer>;
};

const StyledContainer = styled.g`
  font-family: Lato, sans-serif;
  text-align: justify;
  line-height: 1.33;

  a {
    text-decoration: none;
    color: black;
  }

  a:focus, a:hover {
    color: #008;

    text,
    path.border {
      fill: #008;
    }

    rect.border {
      stroke: #008;
      fill: #E0E0FF;
    }
  }
`;
