import {
  ForceCenter, ForceCollide, ForceLink, Simulation, forceCenter, forceCollide, forceLink, forceSimulation
} from "d3";
import { Component } from "react";
import { MapDatum, MapEdge } from "../../types";
import { LexiaBoxComponent } from "./Lexia";

interface IMapNewComponentProps {
  nodes: MapDatum[];
  edges: MapEdge[];
  animate?: boolean;
}

function eqById(
  a: string | number | MapDatum,
  b: string | number | MapDatum
) {
  return (typeof a === "object" && typeof b === "object") ? a.id === b.id : a === b;
}

export class MapNewComponent extends Component<IMapNewComponentProps> {
  nodes: MapDatum[] = [];
  edges: MapEdge[] = [];
  boxSize: number = 0.9;
  size: number = 1;

  protected readonly simulation: Simulation<MapDatum, undefined>;
  protected readonly link: ForceLink<MapDatum, MapEdge>;
  // protected readonly gravity: ForceManyBody<MapDatum>;
  protected readonly collide: ForceCollide<MapDatum>;
  protected readonly center: ForceCenter<MapDatum>;
  protected pendingTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor(props: IMapNewComponentProps) {
    super(props);

    // Forces
    this.link = forceLink<MapDatum, MapEdge>()
      .id(d => d.id)
      .strength(1);
    // this.gravity = forceManyBody<MapDatum>();
    this.collide = forceCollide<MapDatum>().radius(this.boxSize / 2);
    this.center = forceCenter();

    // Simulation
    this.simulation = forceSimulation<MapDatum>()
      .force('link', this.link)
      // .force('charge', this.gravity)
      .force('collide', this.collide)
      .force('center', this.center);
    
    if (!props.animate) {
      this.simulation.stop();
    }
    this.simulation.on('tick', () => this.onSimulationTick());

    // Update the graph
    this.updateGraph();
  }

  componentDidUpdate(prevProps: IMapNewComponentProps) {
    const { nodes, edges } = this.props;
    const hasChanged = () => {
      if (nodes.length !== prevProps.nodes.length || edges.length !== prevProps.edges.length) {
        return true;
      }
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id !== prevProps.nodes[i].id) return true;
      }
      for (let i = 0; i < edges.length; i++) {
        const e1 = edges[i], e2 = prevProps.edges[i];
        if (!(eqById(e1.source, e2.source) && eqById(e1.target, e2.target))) {
          return true;
        }
      }
    };

    if (hasChanged()) {
      this.updateGraph();
      this.forceUpdate();
    }

    if (this.props.animate && !prevProps.animate) {
      this.simulation.restart();
    } else if (!this.props.animate && prevProps.animate) {
      this.simulation.stop();
    }
  }

  render() {
    const edges: ReadonlyArray<MapEdge & { source: MapDatum, target: MapDatum }> = this.edges as any;
    const boxScale = this.boxSize;
    const boxes = this.nodes.map((node) =>
      <LexiaBoxComponent x={node.x} y={node.y} scale={boxScale} key={node.id}
        title={node.title} text={node.text} href={node.url?.href} />
    );
    const lines = edges.map(edge => <line
      x1={edge.source.x || 0}
      y1={edge.source.y || 0}
      x2={edge.target.x || 0}
      y2={edge.target.y || 0}
      key={edge.source.id + "," + edge.target.id + "," + edge.n}
    />);
    return <g transform={`matrix(2 0 0 2 -1 -1)`}><g transform={`scale(${1 / this.size})`}>
      <g transform={`translate(0.5, 0.5)`} stroke="#000000" strokeWidth={0.02}>{ lines }</g>
      <g>{ boxes }</g>
    </g></g>;
  }

  protected updateGraph() {
    console.log('updateGraph');
    const { nodes, edges } = this.props;

    // Place nodes in a grid
    const w = Math.ceil(Math.sqrt(nodes.length));
    this.nodes = nodes.map((node, i) => {
      const x = i % w;
      const y = Math.floor(i / w);
      return { ...node, x, y, fx: null, fy: null, vx: 0, vy: 0 };
    });
    this.simulation.nodes(this.nodes);

    // Add edge arity and number
    this.edges = edges.map((edge1) => {    
      let arity = 0, n = 0;
      for (const edge2 of edges) {
        if ((edge1.source === edge2.source && edge1.target === edge2.target) ||
            (edge1.source === edge2.target && edge1.target === edge2.source)) {
          ++arity;
          if (edge2 === edge1) n = arity - 1;
        }
      }
      return { ...edge1, arity, n };
    });
    this.link.links(this.edges);

    // Update the graph
    this.simulation.alpha(1);

    // Calcuate 500 more ticks via setTimeout
    let i = 500;
    const tick = () => {
      if (--i >= 0) {
        this.pendingTimeout = setTimeout(tick, 0);
        this.tick();
      } else {
        this.pendingTimeout = null;
        this.forceUpdate();
      }
    };
    tick();
  }

  protected onSimulationTick() {
    if (!this.pendingTimeout) {
      this.forceUpdate();
    }
  }

  protected tick() {
    // Simulation tick
    this.simulation.tick(10);

    // Normalize the graph
    const xs = this.nodes.map(node => node.x || 0);
    const ys = this.nodes.map(node => node.y || 0);
    const minX = Math.min(...xs);
    const minY = Math.min(...ys);
    const maxX = Math.max(...xs) - minX;
    const maxY = Math.max(...ys) - minY;
    this.size = Math.max(maxX, maxY) + this.boxSize;
    for (const node of this.nodes) {
      node.x = Number.isFinite(node.x) ? (node.x! - minX) : 0;
      node.y = Number.isFinite(node.y) ? (node.y! - minY) : 0;
    }

    // this.collide.radius(this.boxSize / 2);
  }
}
