import { navigate } from "gatsby";
import MapEditorComponent from "../components/map/Map";

const MapPage = (props: { "*": unknown }) => {
  if (typeof document === "undefined") return;
  const path = props["*"] + "";
  if (!path) return navigate("/o/");
  return <MapEditorComponent path={path} />;
};

export default MapPage;
