export const ZOOM_FACTOR = 0.7924;
export const MIN_ZOOM = 10;
export const MAX_ZOOM = 10000;

export function fixZoom(newZoom: number): number {
  if (!Number.isFinite(newZoom)) return NaN;
  if (newZoom < MIN_ZOOM) {
    newZoom = MIN_ZOOM;
  } else if (newZoom > MAX_ZOOM) {
    newZoom = MAX_ZOOM;
  } else {
    let d = 5, zz = newZoom;
    for (; zz >= 1000; zz /= 10) d *= 10;
    if (zz > 215) d *= 2;
    if (zz > 464) d *= 2;
    newZoom = Math.round(newZoom / d) * d;
  }
  return newZoom;
}

export function zoomIn(zoom: number): number {
  return fixZoom(zoom / ZOOM_FACTOR);
}

export function zoomOut(zoom: number): number {
  return fixZoom(zoom * ZOOM_FACTOR);
}
