/* eslint-disable */
import raw_i_help from "./help.menu";


function menu(Command, Link, Submenu, Divider, Import) {
	const i_help = raw_i_help(...arguments);
	return [
		Submenu("Plik", [
			Command("Nowa lekcja", "New", "file outline"),
			Command("Podgląd", "Preview", "question circle"),
			Command("Drukuj...", "Print", "print"),
			Command("Wyjdź", "Quit", "arrow left")
		]),
		Submenu("Edytuj", [
			Command("Cofnij", "Undo", "undo"),
			Command("Powtórz", "Redo", "redo"),
			Divider(),
			Command("Wytnij", "Cut", "cut"),
			Command("Kopiuj", "Copy", "copy"),
			Command("Wklej", "Paste", "paste"),
			Divider(),
			Command("Znajdź...", "Search", "search")
		]),
		i_help
	];
};

export default menu;
