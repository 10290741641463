import { MapDatum, MapEdge } from "../../types";

export function generateGraph(n: number, e: number): { nodes: string[], edges: [number, number][] } {
  let nodes: string[] = [], edges: [number, number][] = [];

  for (var i=0; i<n; ++i) {
    if (i > 0) {
      for (var j=Math.floor(Math.random() * 3); j>=0; --j) {
        edges.push([i, Math.floor(Math.random() * nodes.length)]);
      }
    }
    nodes.push("Lexia " + (i + 1));
  }
  for (var i=0; i<e; ++i) {
    edges.push([
      Math.floor(Math.random() * nodes.length),
      Math.floor(Math.random() * nodes.length),
    ]);
  }

  edges.sort((a, b) => a[0] - b[0] || a[1] - b[1]);
  let prev = [NaN, NaN];
  edges = edges.filter((edge) => {
    if (edge[0] === edge[1] || edge[0] === prev[0] && edge[1] === prev[1]) return false;
    prev = edge;
    return true;
  });

  return {nodes, edges};
}

export function generateTestMap(): { nodes: MapDatum[], edges: MapEdge[] } {
  const { nodes, edges } = generateGraph(50, 15);
  const mapNodes: MapDatum[] = nodes.map((title, i) => ({
    id: i,
    title,
    url: new URL("about:blank#" + i),
    path: "/",
    type: "lexia",
  }));
  const mapEdges: MapEdge[] = edges.map(([a, b], i) => ({
    id: i,
    source: a,
    target: b,
    label: "Link " + i,
  }));
  return { nodes: mapNodes, edges: mapEdges };
}
