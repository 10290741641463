import { navigate } from "gatsby";
import { FC, useEffect, useId, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from "styled-components";
import { previousLocation } from "../../history";
import { ToolbarSplit } from "../menu/MenuBase";
import { MAX_ZOOM, MIN_ZOOM, zoomIn, zoomOut } from './zoom';
import { TCommand } from "../menu/MenuTypes";

function getPreviousTitle() {
  if (!previousLocation?.pathname) return;
  const match = /^\/([^/]+)\//.exec(previousLocation.pathname);
  if (!match) return;

  switch (match[1]) {
    case "o":
      return "Lista linii fabularnych";
    case "edit":
      return "Powrót do edytora";
    default:
      console.warn("Nieznany punkt powrotu:", match[1]);
  }
}

type MapToolbarProps = {
  execCommand: (command: TCommand) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  nodeSelected: boolean;
};
export const MapToolbar: FC<MapToolbarProps> = ({
  execCommand, nodeSelected, zoom, setZoom: _setZoom
}) => {
  const cmd = (command: TCommand) => () => execCommand(command);
  const setZoom = (zoom: number) => Number.isFinite(zoom) && _setZoom(zoom);
  const [rawValue, setRawValue] = useState(zoom.toString());
  const [valid, setValid] = useState(true);
  const inputId = useId();

  useEffect(() => {
    setRawValue(zoom.toString());
    setValid(Number.isFinite(zoom) && zoom >= MIN_ZOOM && zoom <= MAX_ZOOM);
  }, [zoom]);

  const validateInputZoom = (value: string): {value: string, valid: boolean} => {
    if (!value) return {value, valid: false};
    let zoom = Math.round(+value);
    if (Number.isFinite(zoom)) {
      if (zoom < MIN_ZOOM) return {value, valid: false};
      if (zoom > MAX_ZOOM) zoom = MAX_ZOOM;
    } else {
      zoom = 100;
      value = "100";
    }
    setZoom(zoom);
    if (zoom !== +value) {
      value = zoom.toString();
    }
    return {value, valid: true};
  };

  const setZoomFromInput = (e: any) => {
    const zoom: unknown = e.target.value;
    if (typeof zoom !== "string") return void console.warn("Invalid value type in event");
    const {value, valid} = validateInputZoom(zoom);
    setRawValue(value);
    setValid(valid);
  };

  return <>
    <Button onClick={() => navigate(-1)} basic size="small">
      <Icon name="arrow left" />
      {getPreviousTitle() || "Powrót"}
    </Button>
    <Button onClick={cmd("New")} basic size="small">
      <Icon name="newspaper outline" />
      Nowa leksja
    </Button>
    <ToolbarSplit />
    <Button onClick={cmd("Start")} basic size="small" disabled={!nodeSelected}>
      <Icon name="home" />
      Ustaw jako startową
    </Button>
    <ToolbarSplit>
      <div className="ui labeled action input">
        <label className="ui label" htmlFor={inputId}>Powiększenie</label>
        <StyledInput min={MIN_ZOOM} max={MAX_ZOOM} value={rawValue} id={inputId}
          onChange={setZoomFromInput} className={valid ? "" : "error"} />
        <label className="ui label" htmlFor={inputId}>%</label>
        <Button icon="search plus" title="Przybliż" disabled={zoom >= MAX_ZOOM}
          onClick={() => setZoom(zoomIn(zoom))} />
        <Button icon="search minus" title="Oddal" disabled={zoom <= MIN_ZOOM}
          onClick={() => setZoom(zoomOut(zoom))} />
      </div>
    </ToolbarSplit>
  </>;
};

const StyledInput = styled.input`
  width: 4em;
  text-align: right !important;
  border-right: none !important;
  padding-right: 0 !important;

  & + .ui.label {
    border-radius: 0;
    background-color: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-left: 0;
    padding-left: 1ex;
  }
  &:focus + .ui.label {
    border-color: #85b7d9;
  }

  &.error {
    &, & + .ui.label {
      border-color: #e0b4b4 !important;
      background-color: #fff6f6 !important;
      color: #9f3a38 !important;
    }
  }
`;
